
import MediaQuery from 'react-responsive'
declare var require: any

var React = require('react');
var ReactDOM = require('react-dom');

function ServiceHorizontalLine() {

    return (
        <MediaQuery query="(min-device-width: 1024px)">
            <div class="row cf-bd-1">
                <div class="col-sm-9">
                    <hr color='#ed44d6' />
                </div>
                <div class="col-sm-3"></div>
            </div>
        </MediaQuery>
    );
}

function AddServices(category, services) {
    return (
        <div>
            <div class="text-center"><h4><strong> {category} </strong></h4></div>
            {services.map(function (service, idx) {
                return (
                    <div class="row">
                        <div class="col-sm-6 col-xs-6 text-right"><strong> {service.name} </strong></div>
                        <div class="col-sm-6 col-xs-6 cf-bd-1"> {service.price} </div>
                    </div>
                )
            })}
        </div>
    );
}

function Wax() {
    const services = [
        { name: 'Eyebrow', price: '$20' },
        { name: 'Forehead', price: '$8' },
        { name: 'Chin', price: '$8' },
        { name: 'Lower Lip', price: '$7' },
        { name: 'Upper Lip', price: '$8' },
        { name: 'Sides', price: '$12' },
        { name: 'Nose', price: '$15' },
        { name: 'Shoulder', price: '$20' },
        { name: 'Full Face (No Eyebrow)', price: '$40' },
        { name: 'Neck', price: '$12' },
        { name: 'Underarms', price: '$20' },
        { name: 'Arms Half', price: '$25' },
        { name: 'Arms Full', price: '$40' },
        { name: 'Legs Half', price: '$40' },
        { name: 'Legs Full', price: '$55+' },
        { name: 'Back Half', price: '$30' },
        { name: 'Back Full', price: '$45' },
        { name: 'Chest', price: '$30' },
        { name: 'Tummy', price: '$30' },
        { name: 'Chest & Tummy', price: '$50' },
        { name: 'Butt', price: '$30' },
        { name: 'Bikini', price: '$35' },
        { name: 'Brazilian (maintenance)', price: '$55' },
        { name: 'Brazilian (5+ week)', price: '$65' },
        { name: 'Arms Full, Legs Half, Underarms', price: '$80' },
        { name: 'Arms Full, Legs Full, Underarms', price: '$95' },
        { name: 'Full Body (without Brazilian)', price: '$185' }
    ];

    return AddServices('Wax', services);
}

function Facials() {
    const services = [
        { name: 'Express (30 min)', price: '$55' },
        { name: 'Hydrating Mist', price: '$94' },
        { name: 'VLCC Diamond', price: '$94' },
        { name: 'Clarifying Acne', price: '$98' },
        { name: 'Vitamin C - Glow', price: '$98' },
        { name: 'Sensitive Skin', price: '$98' },
        { name: 'Brightening Customize Facial', price: '$110' },
        { name: 'Sun Damage Repair', price: '$110' },
        { name: 'Microdermabrasion Customize Facial', price: '$115' },
        { name: '90 Minute Luxury Facial', price: '$150' },
        { name: '90 Minute Customize Facial', price: '$150' }
    ];

    return AddServices('Facials', services);
}

function Threading() {
    const services = [
        { name: 'Eyebrow', price: '$14' },
        { name: 'Forehead', price: '$7' },
        { name: 'Chin', price: '$7' },
        { name: 'Lower Lip', price: '$7' },
        { name: 'Upper Lip', price: '$7' },
        { name: 'Neck', price: '$10' },
        { name: 'Sides', price: '$15' },
        { name: 'Full Face (No Eyebrow)', price: '$38' },
        { name: 'Eyebrow, Upper Lip, Chin', price: '$22' },
        { name: 'Eyebrow, Forehead, Upper Lip', price: '$22' },
        { name: 'Eyebrow, Forehead, Chin', price: '$22' },
        { name: 'Eyebrow, Forehead, Upper Lip, Chin', price: '$27' }
    ];

    return AddServices('Threading', services);
}

function Bleach() {
    const services = [
        { name: 'Full Face (including neck)', price: '$40' },
        { name: 'Skin Whitening Bleach With Mask', price: '$50' },
        { name: 'Arms Full, Underarms', price: '$60' },
        { name: 'Under Arms', price: '$25' },
        { name: 'Full Arms', price: '$50' },
        { name: 'Half Back', price: '$40' },
        { name: 'Full Back', price: '$55' }
    ];

    return AddServices('Bleach', services);
}

function LashAndTinting() {
    const services = [
        { name: 'Lash Lift', price: '$75' },
        { name: 'Lash Tinting', price: '$25' },
        { name: 'Lash Lift & Tint', price: '$90' },
        { name: 'Eyebrow Tinting', price: '$20' }
    ];

    return AddServices('Lash & Tinting', services);
}


function AdvanceTreatments() {
    const services = [
        { name: 'Acne Lift Peel', price: '$95' },
        { name: 'Brightening Lift Peel', price: '$95' },
        { name: 'Dermaplaning Brightening Facial', price: '$120' },
        { name: 'Micro Needling Infusion', price: '$140' }
    ];

    return AddServices('Advanced Treatments', services);
}

function BackTreatments() {
    const services = [
        { name: 'Microdermabrasion Back', price: '$110' },
        { name: 'Brightening Back Facial', price: '$110' },
        { name: 'Purifying Back Facial', price: '$110' }
    ];

    return AddServices('Back Treatments', services);
}

function FacialAddOns() {
    const services = [
        { name: 'High Frequency', price: '$5' },
        { name: 'Face Mask', price: '$10+' },
        { name: 'D Tan Mask', price: '$30' },
        { name: 'LED Face Mask', price: '$30' },
        { name: 'Cold Stone Facial Massage', price: '$15' },
        { name: 'Eye Framing & Brightening', price: '$20' },
        { name: 'Scalp Massage (25 min)', price: '$45' }
    ];

    return AddServices('Facial add-ons', services);
}

function Makeup() {
    const services = [
        { name: 'Party Makeup', price: '$140+' },
        { name: 'Hair Styling', price: '$60+' },
        { name: 'Bridal Makeup', price: '$250+' },
        { name: 'Bridal Hair Styling', price: '$100+' },
        { name: 'Saree Draping', price: '$40+' }
    ];

    return AddServices('Makeup & Styling', services);
}

function Services() {
    return (
        <div id="services" class="container-fluid">
            <br />
            <br />
            <br />
            <br />
            <br />
            <div class="row">
                <div class="col-sm-2"></div>
                <div class="col-sm-4">
                    <Wax />
                    <br />
                    <Threading />
                    <br />
                    <LashAndTinting />
                    <br />
                    <Bleach />
                    <br />
                </div>
                <div class="col-sm-4">
                    <Facials />
                    <br />
                    <FacialAddOns />
                    <br />
                    <BackTreatments />
                    <br />
                    <AdvanceTreatments />
                    <br />
                    <Makeup />
                </div>
                <div class="col-sm-2"></div>
            </div>
        </div>
    );
}

function GiftCard() {
    return (
        <a class="book-button" target="_top" href="https://squareup.com/gift/MLTMPWW1AFSXZ/order" rel="nofollow">Gift Card</a>
    );
}

function SocialPlatformLogo() {
    return (
        <div>
            <a class="fa fa-whatsapp" href="https://wa.me/14254949649" target="_blank" />
            <a class="fa fa-facebook" href="https://www.facebook.com/beautydoorspa" target="_blank" />
            <a class="fa fa-instagram" href="https://www.instagram.com/beautydoorspa" target="_blank" />
        </div>
    );
}

function Navbar() {
    return (
        <nav class="navbar navbar-default navbar-fixed-top">

            <MediaQuery query="(min-device-width: 1024px)">
                <div class="row">
                    <div class="col-sm-3"></div>
                    <div class="col-sm-6">
                        <h1 class="text-center"><strong>BeautyDoor</strong></h1>
                    </div>
                    <div class="col-sm-3 text-center">
                        <SocialPlatformLogo />
                    </div>
                </div>
                <div class="text-center"> <BookNow /> </div>
            </MediaQuery>

            <MediaQuery query="(max-device-width: 1024px)">
                <h1 class="text-center"><strong>BeautyDoor</strong></h1>
                <div class="text-center"> <BookNow /> </div>
            </MediaQuery>
        </nav>
    );
}

function BookNow() {
    return (
        <a class="book-button" target="_top" href="https://squareup.com/appointments/book/ws46u2rjxw9x2q/LR4RM3GTV876E/start" rel="nofollow">Book Now</a>
    );
}

function BookNowRevert() {
    return (<h4> Sorry, we are temporarily closed. Our team is working diligently to prepare the new location, and we look forward to serving you again in late March. Stay tuned for exciting updates and announcements. We can�t wait to welcome you back! </h4>);
}

function TemporaryClosingUpdate() {
    return (
        <div>
            <h3 class="text-center"> Sorry!! We are closed until March 5th. </h3>
        </div>
    );
}

function LinkedPhoneNumber() {
    return (
        <a href="tel:+14254949649">4254949649</a>
    );
}

function LinkedPhoneNumberSMS() {
    return (
        <a href="sms://+14254949649"> Send a SMS </a>
    );
}

function LinkedEmail() {
    return (
        <a href="mailto:beautydoorspa@gmail.com">beautydoorspa@gmail.com</a>
    );
}


const beautyDoorLocation = "https://www.google.com/maps/place/Beauty+Door/@47.8235716,-122.2416235,17z/data=!3m1!4b1!4m5!3m4!1s0x4c0ba718ac66c7fb:0x3ede18bfd111c812!8m2!3d47.823568!4d-122.2394348";

function LinkedAddress() {
    return (
        <a href={beautyDoorLocation} target="_blank">
            4114 198th St SW Suit 2A
            <br />
            Lynnwood, WA 98036
        </a>
    );
}

function ContactDetails() {
    return (
        <div>
            <h4><strong> Lynnwood </strong></h4>
            <br />
            <div class="row">
                <div class="col-sm-2 col-xs-2 glyphicon glyphicon-map-marker"></div>
                <div class="col-sm-10 col-xs-10"><LinkedAddress />
                </div>
            </div>
            <br />

            <div class="row">
                <div class="col-sm-2 col-xs-2 glyphicon glyphicon-earphone"></div>
                <div class="col-sm-10 col-xs-10"><LinkedPhoneNumber /></div>
            </div>

            <br />
            <div class="row">
                <div class="col-sm-2 col-xs-2 glyphicon glyphicon-comment"></div>
                <div class="col-sm-10 col-xs-10"><LinkedPhoneNumberSMS /></div>
            </div>
            <br />
        </div>
    );
}

function AddBusinessHours(businessHours) {
    return (
        <div>
            <h4><strong> Business Hours </strong></h4>
            <br />
            {businessHours.map(function (businessHour, idx) {
                return (
                    <div class="row">
                        <div class="col-sm-6 col-xs-6"><strong> {businessHour.day} </strong></div>
                        <div class="col-sm-6 col-xs-6"> {businessHour.value} </div>
                    </div>
                )
            })}
        </div>
    );
}

function BusinessHours() {
    const businessHours = [
        { day: 'Monday', value: '11am to 6pm' },
        { day: 'Tuesday', value: '11am to 6pm' },
        { day: 'Wednesday', value: '11am to 6pm' },
        { day: 'Thursday', value: '11am to 6pm' },
        { day: 'Friday', value: '11am to 6pm' },
        { day: 'Saturday', value: '11am to 6pm' },
        { day: 'Sunday', value: 'Closed' },
    ];

    return AddBusinessHours(businessHours);
}

function ContactForm() {
    return (
        <div>
            <div class="row">
                <div class="col-sm-6 form-group">
                    <input class="form-control" id="name" name="name" placeholder="Name" type="text" required />
                </div>
                <div class="col-sm-6 form-group">
                    <input class="form-control" id="email" name="email" placeholder="Email" type="email" required />
                </div>
            </div>
            <textarea class="form-control" id="comments" name="comments" placeholder="Comment" rows="5"></textarea><br />
            <div class="row">
                <div class="col-sm-12 form-group">
                    <button class="btn btn-default pull-right" type="submit">Send</button>
                </div>
            </div>
        </div>
    );
}

function Contact() {
    return (
        <div id="contact" class="container-fluid">
            <br />
            <h2 class="text-center"> Contact us </h2>
            <div class="row">
                <div class="col-sm-4"> </div>
                <div class="col-sm-4">
                    <div class="row">
                        <hr color='#ed44d6' />
                        <div class="col-sm-6">
                            <ContactDetails />
                        </div>

                        <div class="col-sm-6">
                            <BusinessHours />
                        </div>
                    </div>
                    <br />
                    <br />
                    <div class="text-center">
                        <GiftCard />
                    </div>

                    <br />
                    <br />
                    <div class="text-center">
                        <SocialPlatformLogo />
                    </div>

                </div>
                <div class="col-sm-4"> </div>
            </div>
        </div>
    );
}

function Footer() {
    return (
        <footer class="container-fluid text-center">
            <a href="#services" title="To Top">
                <span class="glyphicon glyphicon-chevron-up"></span>
            </a>
        </footer>
    );
}

function Metadata() {
    return (
        <head>
            <meta charset="UTF-8" />
            <meta name="description" content="Beauty Salon - Esthetic services" />
            <meta name="keywords" content="Beauty salon, spa, esthetic, esthetician, master esthetician, wax, facial, threading, bleach, Microdermabrasion, eyebrow threading" />
            <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        </head>
    );
}

ReactDOM.render(
    <body id="beautyDoorPage" data-spy="scroll" data-target=".navbar" data-offset="60">
        <Navbar />
        <Services />
        <Contact />
        <Footer />
    </body>,
    document.getElementById('beautyDoorRoot')
);